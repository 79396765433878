import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getLp2b(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/lp2b', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addLp2b(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/lp2b/create', data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateLp2b(_, { id, data }) {
            return new Promise((resolve, reject) => {
                axios.put('/lp2b/update/' + id, data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteLp2b(_, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/lp2b/delete/' + id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        selectedAction(_, {action, data}) {
            return new Promise((resolve, reject) => {
                axios.post('/lp2b/selected_action/' + action, { data: data }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        exportReport(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/lp2b/selected_action/export_excel',  { params: _params, responseType: 'blob' }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        ReportByDate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/lp2b/selected_action/export_pdf', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getKecamatan(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/kecamatan', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        importLp2b(_, file) {
            return new Promise((resolve, reject) => {

                const formData = new FormData();
                formData.append('file', file);
                const headers = { 'Content-Type': 'multipart/form-data' };

                let config = {
                    headers
                }
  
                axios.post('/lp2b/import', formData, config).then(resp => {
                    resolve(resp)
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentase(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/lp2b/persentase', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        totalLuas(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/lp2b/total-luas', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        totalLuasKecamatan(_, _params) {
            console.log(_params, "hallo")
            return new Promise((resolve, reject) => {
                axios.get('/lp2b/total-luas-kecamatan', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        totalLuasKelurahan(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/lp2b/total-luas-kelurahan', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getTahun(_) {
            return new Promise((resolve, reject) => {
                axios.get('/lp2b/tahun').then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
    },
    getters: {

    }

}